<template>
  <div class="main-wrapper" ref="discountCodesContainer">
    <header>
      <nav-bar></nav-bar>
    </header>
    <main class="content-wrapper">
      <div class="container mt-4">
        <div class="has-text-centered is-size-2 has-text-primary mb-4 mt-2">Temporary Discount Codes Configuration Panel</div>

        <template v-if="!isAdmin">
          <figure class="image is-200x200 is-centered mx-auto">
            <img src="https://img-9gag-fun.9cache.com/photo/aoPvg7w_460s.jpg" />
          </figure>
          <p class="has-text-centered has-text-danger">
            Niestety nie jesteś zalogowany kontem z uprawnieniami administratorskimi by móc korzystać z tej funkcjonalności.
          </p>
        </template>

        <template v-if="editMode">
          <discount-code-editor :discount-code="discountCodeToEdit" @submit="onSubmit" @return="exitEditMode"></discount-code-editor>
        </template>
        <template v-else>
          <b-button class="button is-fullwidth is-primary mb-3" @click="onCreate()">CREATE NEW</b-button>
          <discount-code-list-item
            v-for="discountCode in discountCodes"
            :key="discountCode.discount_code"
            :discount-code="discountCode"
            @edit="onEdit(discountCode)"
            @delete="tryToDelete(discountCode)"
          ></discount-code-list-item>
        </template>
      </div>
    </main>

    <footer-view></footer-view>
  </div>
</template>

<script>
import RequestMixin from "@/shared/mixins/RequestMixin";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import { mapActions, mapGetters, mapState } from "vuex";
import NavBar from "@/web/components/navbar/NavBar";
import DiscountCodeListItem from "@/web/views/dev/discountcodes/DiscountCodeListItem";
import DiscountCodeEditor from "@/web/views/dev/discountcodes/DiscountCodeEditor";
import axios from "@/web/services/axios";
import FooterView from "@/shared/components/Footer";

export default {
  name: "DiscountCodes",
  mixins: [RequestMixin, AuthorizedRouteMixin],
  components: { DiscountCodeListItem, DiscountCodeEditor, NavBar, FooterView },

  data() {
    return {
      editMode: false,
      discountCodeToEdit: null,
      discountCodes: [],
    };
  },

  mounted() {
    this.fetchDiscountCodes();
    this.fetchEventTickets({ eventId: this.eventId });
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("auth", ["isAdmin"]),
  },

  watch: {
    error(newVal) {
      if (newVal) {
        this.$root.openErrorModal(newVal);
        this.error = null;
      }
    },
  },

  requests: {
    async fetchDiscountCodes() {
      const res = await axios.get(`events/${this.eventId}/discounts`);
      this.discountCodes = res.data;
    },

    async createDiscount(discountCode) {
      await axios.post(`events/${this.eventId}/discount`, discountCode);
    },

    async updateDiscount(discountCode) {
      await axios.patch(`events/${this.eventId}/discount/${discountCode.id}`, discountCode);
      await this.fetchDiscountCodes();
    },

    async deleteDiscount(discountCode) {
      await axios.delete(`events/${this.eventId}/discount/${discountCode.id}`);
      await this.fetchDiscountCodes();
    },
  },

  methods: {
    ...mapActions("eventTickets", { fetchEventTickets: "fetch" }),

    onCreate() {
      this.editMode = true;
    },

    onEdit(discountCode) {
      this.discountCodeToEdit = discountCode;
      this.editMode = true;
    },

    tryToDelete(discountCode) {
      this.$root.openConfirmationModal(
        this.$t("discount_codes.delete_confirmation_title"),
        this.$t("discount_codes.delete_confirmation_description"),
        {
          confirm: () => {
            this.onDelete(discountCode);
          },
          cancel: () => {
            /** no-op **/
          },
        }
      );
    },

    async onDelete(discountCode) {
      await this.deleteDiscount(discountCode);
      await this.fetchDiscountCodes();
    },

    async onSubmit(discountCode) {
      if (this.discountCodeToEdit) {
        await this.updateDiscount(discountCode);
      } else {
        await this.createDiscount(discountCode);
      }
      await this.fetchDiscountCodes();
      this.exitEditMode();
    },

    exitEditMode() {
      this.discountCodeToEdit = null;
      this.editMode = false;
    },
  },
};
</script>

<style scoped>
.main-wrapper {
  overflow-y: auto;
}
</style>
