<template>
  <div class="box">
    <div class="columns is-mobile is-vcentered">
      <div class="column">
        <div>{{ name }}</div>
        <div>{{ discount }}</div>
        <div>{{ `Number of uses: ${usedCount}/${usageLimit}` }}</div>
      </div>
      <div class="column is-narrow" >
        <div class="is-flex is-flex-direction-column" v-if="isEnabled">
          <div class="button is-warning module-order-small-icon-button" @click="onEdit" v-if="usedCount === 0">
            <edit-icon class="svg-icon"></edit-icon>
          </div>
          <div class="button is-danger module-order-small-icon-button mt-1" style="padding: 2px !important" @click="onDelete">
            <trash-bin-icon class="svg-icon"></trash-bin-icon>
          </div>
        </div>
        <div v-else>
          DISABLED
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import EditIcon from "@/assets/icons/edit_2.svg";

export default {
  name: "DiscountCodeListItem",
  components: { TrashBinIcon, EditIcon },

  props: {
    discountCode: {
      type: Object,
      required: false,
    },
  },

  computed: {
    name() {
      return this.discountCode.discount_code;
    },

    usedCount() {
      return this.discountCode.number_of_uses;
    },

    usageLimit() {
      if (this.discountCode.multi_use) {
        return "∞";
      } else {
        return 1;
      }
    },

    discount() {
      if (this.discountCode.discount_amount) {
        return `Amount: ${this.discountCode.discount_amount}`;
      } else {
        return `Percentage: ${this.discountCode.discount_percentage}`;
      }
    },

    isEnabled() {
      return this.discountCode.discount_enabled;
    },
  },

  methods: {
    onDelete() {
      this.$emit("delete");
    },

    onEdit() {
      this.$emit("edit");
    },

    onDisable() {
      this.$emit("disable");
    },
  },
};
</script>

<style scoped></style>
