<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container pb-4">
      <div>
        <div class="field">
          <label class="label">Discount code (Letters are not case-sensitive)</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="'Discount code'" v-model="name" style="text-transform: lowercase" />
          </div>
          <template v-if="$v.name.$error">
            <p v-if="!$v.name.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
          </template>
        </div>

        <div class="field">
          <label class="label mb-1">Mode: percentage/amount</label>
          <dropdown-select-wrapper
            :items="dropdownValues"
            :placeholder="$t('common.select_from_list')"
            v-model="mode"
            :unselectable="false"
            :item-label-function="item => item.label"
            :item-value-function="item => item.value"
          ></dropdown-select-wrapper>
        </div>

        <div class="field" v-if="mode === 0">
          <label class="label">Discount percentage ex. 20 = 20%</label>
          <div class="control">
            <input class="input is-simple" type="number" :placeholder="'Discount percentage'" v-model="discountPercentage" />
          </div>
          <div class="is-size-5 is-bold mt-1">{{ `${Math.min(Math.max(discountPercentage, 0.0), 100.0) || 0} %` }}</div>
          <template v-if="$v.name.$error">
            <p v-if="!$v.discountPercentage.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            <p v-if="!$v.discountPercentage.between" class="help is-danger">{{ 'Value has to be in the range from 0 exclusive to 100 inclusive.' }}</p>
          </template>
        </div>

        <div class="field" v-else>
          <label class="label">Discount amount in the smallest currency unit ex. 1050 = 10,50 USD or 1050 = 1050 HUF</label>
          <div class="control">
            <input class="input is-simple" type="number" :placeholder="'Discount amount'" v-model="discountAmount" />
          </div>
          <div class="is-size-5 is-bold mt-1">{{ `${discountAmount / 100.0} USD/PLN or ${discountAmount || 0} HUF` }}</div>
          <template v-if="$v.discountAmount.$error">
            <p v-if="!$v.discountAmount.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            <p v-if="!$v.discountAmount.minValue" class="help is-danger">{{ "Value has to be greater than 0." }}</p>
            <p v-if="!$v.discountAmount.integer" class="help is-danger">{{ "Value can't be decimal." }}</p>
          </template>
        </div>

        <div class="field">
          <div class="control">
            <label class="checkbox consents">
              <checkbox class="checkbox-input" v-model="multiUse" />
              <span @click="multiUse = !multiUse">MULTI USE</span>
            </label>
          </div>
        </div>

        <div class="field">
          <label class="label">Event tickets:</label>
          <event-ticket-ids-picker v-model="ticketIds"></event-ticket-ids-picker>
          <template v-if="$v.ticketIds.$error">
            <p v-if="!$v.ticketIds.arrayIsNotEmpty" class="help is-danger">{{ $t("discount_codes.ticket_ids_not_empty_error") }}</p>
          </template>
        </div>

        <b-button type="is-primary" class="button has-fullwidth my-2" @click="submit" rounded>{{
          discountCode ? "UPDATE" : "CREATE"
        }}</b-button>
        <b-button type="is-warning" class="button has-fullwidth mb-2" @click="$emit('return')" rounded>CANCEL</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestMixin from "@/shared/mixins/RequestMixin";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import { mapGetters, mapState } from "vuex";
import Checkbox from "@/shared/components/form/Checkbox";
import NavBar from "@/web/components/navbar/NavBar";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";
import EventTicketIdsPicker from "@/web/components/admin/subpanels/modules/views/EventTicketIdsPicker";
import { required, integer, between, minValue } from "vuelidate/lib/validators";

export default {
  name: "DiscountCodeEditor",
  mixins: [RequestMixin, AuthorizedRouteMixin],
  components: { DropdownSelectWrapper, Checkbox, NavBar, EventTicketIdsPicker },

  props: {
    discountCode: {
      type: Object,
      required: false,
    },
  },

  data() {
    if (this.discountCode) {
      return {
        id: this.discountCode.id,
        name: this.discountCode.discount_code,
        mode: this.discountCode.discount_percentage ? 0 : 1,
        discountAmount: this.discountCode.discount_amount,
        discountPercentage: this.discountCode.discount_percentage,
        multiUse: this.discountCode.multi_use,
        ticketIds: this.discountCode.ticket_ids,
      };
    } else {
      return {
        id: null,
        name: "",
        mode: 0,
        discountAmount: 0,
        discountPercentage: 0,
        multiUse: false,
        ticketIds: [],
        createdDiscount: false,
      };
    }
  },

  validations() {
    const validations = {
      name: { required },
      ticketIds: { arrayIsNotEmpty },
    };

    if (this.mode === 0) {
      validations.discountPercentage = { between: between(0.1, 100), required: required };
    } else {
      validations.discountAmount = { minValue: minValue(1), required: required, integer: integer };
    }
    return validations;
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("auth", ["isAdmin"]),

    dropdownValues() {
      return [
        {
          label: "percentage",
          value: 0,
        },
        {
          label: "amount",
          value: 1,
        },
      ];
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const discountCode = {
          id: this.id,
          discount_code: this.name.toLowerCase(),
          discount_amount: this.mode === 0 ? null : this.discountAmount,
          discount_percentage: this.mode === 0 ? Math.min(Math.max(this.discountPercentage, 0.0), 100.0) : null,
          multi_use: this.multiUse,
          ticket_ids: this.ticketIds,
        };
        this.$emit("submit", discountCode);
      }
    },
  },
};

function arrayIsNotEmpty(array) {
  return Array.isArray(array) && array.length;
}
</script>

<style scoped>
.consents {
  font-size: 13px;
}
</style>
